import React from 'react';

import clsx from 'clsx';

import styles from './input.module.scss';

export interface InputProps {
  [x: string]: unknown;
  value: string;
  className?: string;
  disabled?: boolean;
  error?: string;
  showError?: boolean;
}

const Input: React.FC<InputProps> = ({
  value,
  error,
  className,
  disabled,
  showError = true,
  ...props
}) => (
  <div className={styles.inputContainer}>
    <input
      value={value}
      className={clsx(className, styles.input, {
        [styles.error]: error,
      })}
      {...props}
      disabled={disabled}
    />
    {showError && <p>{error}</p>}
  </div>
);

export default Input;
