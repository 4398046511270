import React, { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';

import PostContentMenuItem from './post-content-menu-item/post-content-menu-item';

import styles from './post-content-menu.module.scss';

const PostContentMenu = () => {
  const [blockList, setBlockList] = useState<HTMLElement[]>([]);

  const [inViewItems, setInViewItems] = useState<IntersectionObserverEntry[]>(
    []
  );

  const [activeItem, setActiveItem] = useState<string | null>(null);

  const location = usePathname();

  const handleScrollToBlock = (heading: HTMLElement) => {
    heading.style.scrollMarginTop = '24px';
    heading.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const contentBlock = document.querySelector('#post-content');
      const headers = contentBlock?.getElementsByTagName('h2') || [];

      if (headers.length) setBlockList([...headers]);
      else setBlockList([]);
    }
  }, [location]);

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInViewItems((prev) => [...prev, entry]);
          }

          if (!entry.isIntersecting) {
            setInViewItems((prev) =>
              prev?.filter(
                (item) => item.target.textContent !== entry.target.textContent
              )
            );
          }
        });
      },
      { threshold: 0.3 }
    );

    blockList.forEach((item) => {
      intersectionObserver.observe(item);
    });

    return () => {
      intersectionObserver.disconnect();
    };
  }, [blockList]);

  useEffect(() => {
    if (inViewItems.length) {
      const entriesPosition = inViewItems.map(
        (item) => item.target.getBoundingClientRect().y
      );

      const topPosition = Math.min(...entriesPosition);
      const topElement = inViewItems[entriesPosition.indexOf(topPosition)];

      setActiveItem(topElement.target.textContent);
    }
  }, [inViewItems]);

  if (!blockList.length) return null;

  return (
    <ul className={styles.content}>
      {blockList.length &&
        blockList.map((item) => (
          <PostContentMenuItem
            key={item.textContent}
            inViewItem={activeItem}
            item={item}
            onClick={() => handleScrollToBlock(item)}
          />
        ))}
    </ul>
  );
};

export default PostContentMenu;
