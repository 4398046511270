import React, { useEffect, useRef, useState } from 'react';

import DottedProgressBar from './dotted-progress-bar/dotted-progress-bar';

import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useLocale } from 'next-intl';
import type { Swiper as SwiperType } from 'swiper';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { IBanner } from 'services/user.service';
import { useTextDirection } from 'hooks/use-text-direction';

import styles from './dynamic-banner-swiper.module.scss';

import 'swiper/css';

type SwiperVariants = 'explorer' | 'blog';

export interface IDynamicBannerSwiperProps {
  bannerList: IBanner[];
  variant: SwiperVariants;
}

interface ISize {
  height: number;
  src: string;
  width: number;
}

type ISizeList = Record<
  SwiperVariants,
  {
    desktop: ISize;
    mobile: ISize;
    tablet?: ISize;
  }
>;

const swiperSizes: ISizeList = {
  blog: {
    desktop: {
      height: 267,
      src: '/img/dynamic-banner/blog-desktop.jpg',
      width: 352,
    },
    mobile: {
      height: 291,
      src: '/img/dynamic-banner/blog-mobile.jpg',
      width: 343,
    },
    tablet: {
      height: 267,
      src: '/img/dynamic-banner/blog-tablet.jpg',
      width: 304,
    },
  },
  explorer: {
    desktop: {
      height: 267,
      src: '/img/dynamic-banner/explorer-desktop.jpg',
      width: 405,
    },
    mobile: {
      height: 267,
      src: '/img/dynamic-banner/explorer-mobile.jpg',
      width: 343,
    },
  },
};

const DynamicBannerSwiper: React.FC<IDynamicBannerSwiperProps> = ({
  variant,
  bannerList,
}) => {
  const locale = useLocale();
  const dir = useTextDirection();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const swiperRef = useRef<SwiperType | null>(null);
  const isTablet = useMediaQuery('(max-width:768px)');
  const isLaptop = useMediaQuery('(max-width:1024px)');

  const getBannerSize = (type: 'width' | 'height') => {
    if (isTablet) return swiperSizes[variant].mobile[type];

    if (isLaptop)
      return (
        swiperSizes[variant]?.tablet?.[type] ||
        swiperSizes[variant].desktop[type]
      );

    return swiperSizes[variant].desktop[type];
  };

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
      swiperRef.current.changeLanguageDirection(dir);
      swiperRef.current.slideTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <div className={styles.swiper_container}>
      {bannerList?.length ? (
        <Swiper
          loop
          className={styles.swiper}
          direction='horizontal'
          modules={[Autoplay]}
          slidesPerView={1}
          threshold={20}
          autoplay={{
            delay: 7000,
            disableOnInteraction: true,
          }}
          style={{
            height: `${getBannerSize('height')}px`,
            maxWidth: '100%',

            width: `${getBannerSize('width')}px`,
          }}
          onActiveIndexChange={({ realIndex }: { realIndex: number }) => {
            setActiveIndex(realIndex);
          }}
          onBeforeInit={(swiper: SwiperType) => {
            swiperRef.current = swiper;
          }}
        >
          {bannerList?.map((item) => (
            <SwiperSlide key={`${item.uuid}`} className={styles.swiper_slide}>
              <a
                href={item.redirect_url}
                rel='noreferrer'
                target='_blank'
                style={{
                  height: `${getBannerSize('height')}px`,
                  maxWidth: '100%',
                  width: `${getBannerSize('width')}px`,
                }}
              >
                {!isTablet && !isLaptop && (
                  <img
                    alt='banner'
                    height={getBannerSize('height')}
                    src={item.cover_web}
                    width={getBannerSize('width')}
                  />
                )}

                {isLaptop && !isTablet && (
                  <img
                    alt='banner tablet'
                    height={getBannerSize('height')}
                    src={item?.cover_tablet || item.cover_web}
                    width={getBannerSize('width')}
                  />
                )}

                {isTablet && (
                  <img
                    alt='banner mobile'
                    height={getBannerSize('height')}
                    src={item.cover_mobile}
                    width={getBannerSize('width')}
                  />
                )}
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={clsx(styles.swiper, styles.plug)}>
          {!isTablet && !isLaptop && (
            <img
              alt='banner'
              height={getBannerSize('height')}
              src={swiperSizes[variant].desktop.src}
              width={getBannerSize('width')}
            />
          )}

          {isLaptop && !isTablet && (
            <img
              alt='banner tablet'
              height={getBannerSize('height')}
              width={getBannerSize('width')}
              src={
                swiperSizes[variant]?.tablet?.src ||
                swiperSizes[variant].desktop.src
              }
            />
          )}

          {isTablet && (
            <img
              alt='banner mobile'
              height={getBannerSize('height')}
              src={swiperSizes[variant].mobile.src}
              width={isTablet ? '100%' : getBannerSize('width')}
            />
          )}
        </div>
      )}
      {bannerList?.length && bannerList?.length > 1 ? (
        <DottedProgressBar
          activeIndex={bannerList?.length ? activeIndex : 0}
          count={bannerList?.length ?? 3}
        />
      ) : null}
    </div>
  );
};

export default DynamicBannerSwiper;
