import type { AxiosError } from 'axios';
import { useReCaptcha } from 'next-recaptcha-v3';

// v3 section
export const useGoogleRecaptchaV3 = () => {
  const { executeRecaptcha } = useReCaptcha();

  const saveExec = async () =>
    await ((executeRecaptcha && executeRecaptcha('act')) ||
      Promise.resolve(''));

  return { executeV3: saveExec };
};
// v3 section over

export const handleRecaptchaError = async <T>(
  data: T,
  resendMethod: (data: T) => void,
  executeV2: () => Promise<string | null>
) => {
  const recaptcha_token = await executeV2();

  resendMethod({ ...data, recaptcha_token });
};

export const backendErrorToString = (error: AxiosError<any>) => {
  if (error?.response?.data.message) return error?.response?.data.message;

  const entities: string[][] = Object.values(error?.response?.data?.errors);

  return entities.map((entity) => entity.join(', ')).join(' ');
};
