import React, { useMemo, useRef } from 'react';

import { usePathname } from 'app/providers/i18n/i18n.config';
import { useLocale, useTranslations } from 'next-intl';

import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard';
import { getLocaleLink } from 'utils/get-locale-link';

import FacebookIcon from 'public/img/share/facebook.svg';
import LinkIcon from 'public/img/share/link.svg';
import LinkedInIcon from 'public/img/share/linkedin.svg';
import TelegramIcon from 'public/img/share/telegram.svg';
import XIcon from 'public/img/share/X.svg';

import styles from './share-block.module.scss';

type SocialType = 'telegram' | 'facebook' | 'twitter' | 'linkedin';

interface IShareLinkItem {
  icon: JSX.Element;
  link: string;
}

const ShareBlock = () => {
  const locale = useLocale();
  const t = useTranslations('blog');

  const location = usePathname();
  const copyRef = useRef<HTMLButtonElement>(null);

  const getPostUrl = () =>
    getLocaleLink(`https://cryptomus.com${location}`, locale);

  const getUtmString = (type: SocialType) =>
    `?utm_source=blog-share&utm_medium=${type}&utm_campaign=${getPostUrl()}`;

  const [copyLink] = useCopyToClipboard(
    copyRef,
    t('The link has been successfully copied'),
    getPostUrl()
  );

  const shareLinks: IShareLinkItem[] = useMemo(
    () => [
      {
        icon: <TelegramIcon />,
        link: `https://telegram.me/share/url?url=${getPostUrl()}${getUtmString(
          'telegram'
        )}`,
      },
      {
        icon: <XIcon />,
        link: `https://twitter.com/intent/tweet?url=${getPostUrl()}${getUtmString(
          'twitter'
        )}`,
      },
      {
        icon: <FacebookIcon />,
        link: `https://www.facebook.com/sharer.php?u=${getPostUrl()}${getUtmString(
          'facebook'
        )}`,
      },

      {
        icon: <LinkedInIcon />,
        link: `https://www.linkedin.com/sharing/share-offsite/?url=${getPostUrl()}${getUtmString(
          'linkedin'
        )}`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location, locale]
  );

  return (
    <div className={styles.share}>
      <p className={styles.share__title}>{t('Share')}</p>
      <ol className={styles.share__list}>
        {shareLinks.map((item) => (
          <a
            key={item.link}
            className={styles.list__item}
            href={item.link}
            rel='noreferrer'
            target='_blank'
          >
            {item.icon}
          </a>
        ))}
        <button
          ref={copyRef}
          aria-label='Copy'
          className={styles.list__item}
          onClick={copyLink}
        >
          <LinkIcon />
        </button>
      </ol>
    </div>
  );
};

export default ShareBlock;
