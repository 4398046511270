import React from 'react';

import clsx from 'clsx';

import { useTheme } from 'hooks/use-theme';

import styles from './custom-checkbox.module.scss';

interface ICustomCheckboxProps {
  checkedError: boolean;
  disabled: boolean;
  isChecked: boolean;
  setCheckedError: (value: boolean) => void;
  setIsChecked: (value: boolean) => void;
  className?: string;
  isOneRowText?: boolean;
  label?: JSX.Element;
  modal?: boolean;
  size?: 'medium' | 'small';
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  className,
  label,
  size = 'medium',
  isChecked,
  setIsChecked,
  checkedError,
  setCheckedError,
  disabled,
  modal,
  isOneRowText,
}) => {
  const { theme } = useTheme();

  const id = modal ? 'agree_terms_1' : 'agree_terms';

  return (
    <div className={styles.custom_checkbox_wrapper}>
      <input
        checked={isChecked}
        disabled={disabled}
        id={id}
        name='agree_terms'
        type='checkbox'
        className={clsx(styles.custom_checkbox, {
          [styles.dark]: theme === 'dark',
          [styles.custom_checkbox_wrapper_oneRow]: isOneRowText,
        })}
        onChange={(e) => {
          setIsChecked(e.target.checked);

          if (e.target.checked) {
            setCheckedError(false);
          }
        }}
      />
      <label
        htmlFor={id}
        className={clsx(
          styles[size],
          { [styles.error]: checkedError },
          className
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
