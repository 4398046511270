import React from 'react';

import styles from './blog-video.module.scss';

interface IBlogVideoProps {
  videoUrl: string;
}

const BlogVideo: React.FC<IBlogVideoProps> = ({ videoUrl }) => (
  <div className={styles.iframe__wrapper}>
    <iframe
      allowFullScreen
      allow='clipboard-write; encrypted-media;  picture-in-picture; web-share'
      frameBorder='0'
      scrolling='no'
      src={videoUrl}
      title='YouTube video player'
    ></iframe>
  </div>
);

export default BlogVideo;
