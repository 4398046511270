import React from 'react';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import styles from './post-content-menu-item.module.scss';

interface IPostContentMenuItemProps {
  item: HTMLElement;
  onClick: () => void;
  inViewItem?: string | null;
}

const PostContentMenuItem: React.FC<IPostContentMenuItemProps> = ({
  item,
  onClick,
  inViewItem,
}) => {
  const innerTextItem = item.textContent;

  return (
    <li
      className={clsx(styles.content__item, {
        [styles.inView]: inViewItem === innerTextItem,
      })}
      onClick={onClick}
    >
      {innerTextItem}
      {inViewItem === innerTextItem && (
        <motion.div
          className={styles.active_line}
          layoutId='underline'
          transition={{ duration: 0.3, type: 'tween' }}
        ></motion.div>
      )}
    </li>
  );
};

export default PostContentMenuItem;
