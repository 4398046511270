import React from 'react';

import { useTranslations } from 'next-intl';

import { GetStartedButton } from 'ui/get-started-button/get-started-button';

import { useAuth } from 'services/query-hooks/use-auth';

import styles from './get-started-block.module.scss';

const GetStartedBlock: React.FC = () => {
  const t = useTranslations('blog');
  const { isAuth } = useAuth();

  return (
    <section className={styles.get_started_block} id='get-started-block'>
      <div className={styles.content_wrapper}>
        <h2 className={styles.title}>{t('Simplify Your Crypto Journey')}</h2>

        <p className={styles.text}>
          {isAuth
            ? t('blogGetStartedBlogText')
            : t(
                "Want to store, send, accept, stake, or trade cryptocurrencies? With Cryptomus it's all possible — sign up and manage your cryptocurrency funds with our handy tools"
              )}
        </p>
        <GetStartedButton
          fullWidth
          appearance='white'
          className={styles.getStartedButton}
          text={t('Get Started')}
        />
      </div>
    </section>
  );
};

export default GetStartedBlock;
