'use client';

import React, { useCallback, useState } from 'react';

import clsx from 'clsx';
import type { FC, ReactElement, ReactNode } from 'react';

import IconDown from 'icons/common/arrow-down.svg';

import styles from './markdown-table.module.scss';

interface IMarkdownTableProps {
  children: ReactNode;
}

interface TableRowProps {
  children: React.ReactNode;
  isOpen: boolean;
  onToggleRow: (index: number) => void;
  rowIndex: number;
}

const TableRow: React.FC<TableRowProps> = ({
  rowIndex,
  isOpen,
  onToggleRow,
  children,
}) => {
  const handleToggle = useCallback(() => {
    onToggleRow(rowIndex);
  }, [onToggleRow, rowIndex]);

  return (
    <tr className={clsx({ [styles.open]: isOpen })} onClick={handleToggle}>
      {children}
      <td className={clsx(styles.arrow, { [styles.active]: isOpen })}>
        <IconDown />
      </td>
    </tr>
  );
};

export const MarkdownTable: FC<IMarkdownTableProps> = ({ children }) => {
  const [openRows, setOpenRows] = useState<number[]>([]);

  const toggleRow = useCallback((index: number) => {
    if (window?.innerWidth <= 576) {
      setOpenRows((prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index]
      );
    }
  }, []);

  const childrenArray = React.Children.toArray(children) as ReactElement[];

  const headerRow = childrenArray[0] as ReactElement | undefined;

  const headerCells = headerRow
    ? (React.Children.toArray(headerRow.props.children)[0] as
        | ReactElement
        | undefined)
    : undefined;

  const headerText =
    headerCells && Array.isArray(headerCells.props.children)
      ? headerCells.props.children.map(
          (child: ReactElement) => child.props.children
        )
      : [];

  return (
    <table className={styles.blog__table}>
      <thead>{headerRow?.props.children}</thead>
      <tbody>
        {childrenArray.slice(1).map((child) => {
          if (Array.isArray(child.props.children)) {
            return React.Children.map(child.props.children, (row, rowIndex) => {
              if (Array.isArray(row.props.children)) {
                const tableCells = React.Children.map(
                  row.props.children,
                  (cell, cellIndex) => {
                    const normalizedContent = React.Children.map(
                      cell.props.children,
                      (content) =>
                        typeof content === 'string' &&
                        content.includes('<br') ? (
                          <br key={content} />
                        ) : (
                          content
                        )
                    );

                    if (cellIndex > 0 && headerText[cellIndex]) {
                      return (
                        <td
                          className={clsx(styles.table__cell, {
                            [styles.empty]: !cell.props.children,
                          })}
                        >
                          <span className={styles.subtitle}>
                            {cell.props.children && headerText[cellIndex]}
                          </span>
                          {normalizedContent}
                        </td>
                      );
                    }

                    return (
                      <td className={styles.table__cell}>
                        {normalizedContent}
                      </td>
                    );
                  }
                );

                return (
                  <TableRow
                    //eslint-disable-next-line react/no-array-index-key
                    key={rowIndex}
                    isOpen={openRows.includes(rowIndex)}
                    rowIndex={rowIndex}
                    onToggleRow={toggleRow}
                  >
                    {tableCells}
                  </TableRow>
                );
              }

              return row;
            });
          }

          return null;
        })}
      </tbody>
    </table>
  );
};
