import React, { useState } from 'react';
import Link from 'next/link';

import { useMutation } from '@tanstack/react-query';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useLocale, useTranslations } from 'next-intl';
import { toast } from 'react-toastify';

import { Button } from 'ui/button/button';
import CustomCheckbox from 'ui/custom-checkbox/custom-checkbox';
import Input from 'ui/input/input';

import { api } from 'services/api';
import { useGoogleRecaptchaV3 } from 'hooks/use-google-recaptcha-v3';

import styles from './contact-us-blog.module.scss';

export interface IData {
  form: {
    email: string;
    name: string;
    telegram: string;
  };
  recaptcha_token: string;
  type: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

const CheckboxLabel = () => {
  const t = useTranslations('common.form');

  return (
    <p>
      {t('I have read and agree to the')}{' '}
      <Link href='/privacy' target='_blank'>
        {t('Privacy Policy')}
      </Link>
    </p>
  );
};

const ContactUsBlog: React.FC = () => {
  const locale = useLocale();
  const tForm = useTranslations('common.form');
  const tBlog = useTranslations('blog');
  const { executeV3 } = useGoogleRecaptchaV3();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telegram, setTelegram] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [telegramError, setTelegramError] = useState<string>('');
  const [checkedError, setCheckedError] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: (data: IData) => api.post('v1/form/save', data),
    onError: () => {
      toast.error(tForm('Something went wrong'));

      setLoading(false);
    },
    onSuccess: () => {
      setName('');
      setEmail('');
      setTelegram('');
      setIsChecked(false);
      setLoading(false);

      window?.dataLayer?.push({ event: 'landing_post_form' });

      toast.success(tForm('Message sent'));
    },
  });

  const validateEmail = (email: string): RegExpMatchArray | null =>
    String(email)
      .toLowerCase()
      .match(
        /^("[^"]+"|[a-z0-9а-яА-ЯёЁ+_]+([-.][a-z0-9а-яА-ЯёЁ+_]+)*)@[a-z0-9а-яА-ЯёЁ]+([-][a-z0-9а-яА-ЯёЁ]+)*(\.[a-z0-9а-яА-ЯёЁ-]+)*(\.[a-zа-яА-ЯёЁ]{2,11})$/i
      );

  const validateTelegram = (telegram: string): RegExpMatchArray | null =>
    String(telegram).match(/^[a-z0-9_@]+$/i);

  const checkFields = (): boolean => {
    let ok = true;

    if (!name) {
      setNameError(' ');
      ok = false;
    } else if (!email) {
      setEmailError(' ');
      ok = false;
    } else if (name.length < 2) {
      setNameError(tForm('The name must be at least'));
      ok = false;
    } else if (telegram.length < 5 && telegram.length) {
      setTelegramError(tForm('The name must be at least 5 characters'));
      ok = false;
    } else if (name.length > 60) {
      setNameError(tForm('The name must not be greater than'));
      ok = false;
    } else if (!validateEmail(email)) {
      setEmailError(tForm('The email must be a valid email address'));
      ok = false;
    } else if (!validateTelegram(telegram) && telegram.length) {
      if (telegram) {
        setTelegramError(
          tForm('The telegram name must be contain only English characters')
        );
        ok = false;
      } else {
        setTelegramError('');
        ok = true;
      }
    } else if (!isChecked) {
      setCheckedError(true);
      ok = false;
    } else if (!ok) {
      setIsError(true);
    }

    return ok;
  };

  const contactUs = async (
    e: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    e.preventDefault();

    if (!checkFields()) return;

    setLoading(true);

    const token = await executeV3();

    mutation.mutate({
      form: {
        email,
        name,
        telegram: telegram.startsWith('@') ? telegram : `@${telegram}`,
      },
      recaptcha_token: token,
      type: 'contact',
      utm_campaign: Cookies.get('utm_campaign'),
      utm_content: Cookies.get('utm_content'),
      utm_medium: Cookies.get('utm_medium'),
      utm_source: Cookies.get('utm_source'),
      utm_term: Cookies.get('utm_term'),
    });
  };

  const isCheckBoxError = () => {
    if (nameError || telegramError || emailError) return false;

    return checkedError;
  };

  return (
    <div className={styles.question_form} id='question-form'>
      <h2>
        {tBlog(
          'If you have a question, leave your contact, and we will get back to you'
        )}
      </h2>
      <form
        className={clsx(styles.form, {
          [styles.with_error]: isError && !isCheckBoxError(),
        })}
      >
        <div className={styles.inputs}>
          <Input
            disabled={loading}
            error={nameError}
            maxLength={255}
            placeholder={tForm('Full name*')}
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
              setNameError('');
            }}
          />
          <Input
            className={styles.long_placeholder}
            disabled={loading}
            error={telegramError}
            maxLength={255}
            placeholder='Telegram/WhatsApp/Skype'
            value={telegram}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTelegram(e.target.value);
              setTelegramError('');
            }}
          />
          <Input
            disabled={loading}
            error={emailError}
            maxLength={255}
            placeholder={tForm('Email*')}
            value={email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
          />
          <Button
            className={styles.btn}
            loading={loading}
            size='s'
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => contactUs(e)}
          >
            {tForm('Send')}
          </Button>

          <div
            className={clsx(styles.checkbox_wrapper, {
              [styles.checkbox_wrapper_short]: ['ru'].includes(locale),
            })}
          >
            <CustomCheckbox
              checkedError={checkedError}
              disabled={loading}
              isChecked={isChecked}
              label={<CheckboxLabel />}
              setCheckedError={setCheckedError}
              setIsChecked={setIsChecked}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactUsBlog;
