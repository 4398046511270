import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import DynamicBannerSwiper from '../../dynamic-banner-swiper/dynamic-banner-swiper';
import PostContentMenu from '../additional-block/post-content-menu/post-content-menu';
import ShareBlock from '../additional-block/share-block/share-block';
import BlogVideo from '../blog-video/blog-video';
import ContactUsBlog from '../contact-us-blog/contact-us-blog';
import GetStartedBlock from '../get-started-block/get-started-block';
import MarkdownRenderer from '../markdown-render/markdown-render';
import PostInfo from '../post-info/post-info';
import RateArticle from '../rate-article/rate-article';
import Tag from '../tag/tag';

import { Divider } from '@mui/material';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import Dropdown from 'ui/dropdown/dropdown';

import type { IBanner } from 'services/user.service';
import type { IPost, ITag } from 'types/model.post';

import IconLeft from 'icons/common/icon-btn-arrow-left.svg';
import IconRight from 'icons/common/icon-btn-arrow-right.svg';

import styles from './post-content.module.scss';

interface IPostContent {
  bannerListData: IBanner[];
  post: IPost;
  blog?: boolean;
  commentsCount?: number;
}

const PostContent: React.FC<IPostContent> = ({
  post,
  commentsCount,
  bannerListData,
  blog = true,
}) => {
  const t = useTranslations('blog');
  const links: ITag[] | undefined = post?.tags?.slice(0, 3); // get first 3 links
  const dropdownLinks: ITag[] | undefined = post?.tags?.slice(3); // get remaining links

  const getHideDivider = () => <div></div>;

  return (
    <article className={styles.post__article} dir='ltr' id={String(post?.id)}>
      <section className={`${styles.article__img} container`}>
        <div className={styles.article__img_wrapper}>
          <Image
            fill
            priority
            alt={post?.title}
            quality={100}
            src={post?.cover ?? '/img/common/no-icon.webp'}
          />
        </div>
      </section>
      <section className={styles.content_container}>
        <div className={styles.main_container}>
          <div className={`${styles.content}`}>
            <div className={styles.content_wrapper}>
              <div className={styles.content__info}>
                <PostInfo
                  blog={blog}
                  comments_count={blog ? commentsCount : undefined}
                  date={post?.updated_at}
                />

                <h1 className={styles.title}>{post?.title}</h1>

                {blog && links && (
                  <div className={styles.content__social}>
                    {links?.map((tag) => (
                      <Tag
                        key={tag.id}
                        link={`/blog?tag=${tag.id}`}
                        title={tag.name}
                      />
                    ))}

                    {dropdownLinks && dropdownLinks?.length > 0 && (
                      <Dropdown
                        parent={<Tag title={`+${dropdownLinks?.length}`} />}
                        links={dropdownLinks.map((tag) => ({
                          id: tag.id,
                          item: (
                            <Tag
                              key={tag.id}
                              link={`/blog?tag=${tag.id}`}
                              title={tag.name}
                            />
                          ),
                        }))}
                      />
                    )}
                  </div>
                )}
              </div>

              {post?.video_url?.length && post?.video_position_top && (
                <div className={styles.video__wrapper_top}>
                  <BlogVideo videoUrl={post?.video_url} />
                </div>
              )}

              <div className={styles.content__inner} id='post-content'>
                <MarkdownRenderer markdownText={post?.body ?? ''} />
              </div>

              {post?.video_url?.length && !post?.video_position_top && (
                <div className={styles.video__wrapper_bottom}>
                  <BlogVideo videoUrl={post?.video_url} />
                </div>
              )}
            </div>
            {blog && (
              <>
                <RateArticle postId={post.id} />
                <div className={`${styles.linked_posts} container`}>
                  <div className={`${styles.linked_posts_wrapper}`}>
                    {post?.previous_post && (
                      <Link
                        className={styles.navigation__btn}
                        href={`/blog/${post?.previous_post?.alias}`}
                      >
                        <div className={styles.btn__icon}>
                          <div className={styles.btn__icon_wrapper}>
                            <IconLeft className={styles.btn__icon_previous} />
                          </div>
                        </div>
                        <div className={styles.content_wrapper}>
                          <span className={styles.btn__name}>
                            {t('Previous post')}
                          </span>
                          <span className={styles.post__title}>
                            {post?.previous_post
                              ? post?.previous_post?.title
                              : ''}
                          </span>
                        </div>
                      </Link>
                    )}

                    {getHideDivider()}

                    {post?.next_post && (
                      <Link
                        className={styles.navigation__btn}
                        href={`/blog/${post?.next_post?.alias}`}
                      >
                        <div className={styles.content_wrapper}>
                          <span className={styles.btn__name}>
                            {t('Next post')}
                          </span>
                          <span className={styles.post__title}>
                            {post?.next_post ? post?.next_post?.title : ''}
                          </span>
                        </div>
                        <div className={styles.btn__icon}>
                          <div className={styles.btn__icon_wrapper}>
                            <IconRight className={styles.btn__icon_next} />
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
                <div className={styles.forms_wrapper}>
                  <ContactUsBlog />
                  <div className={clsx(styles.additional_block, styles.mobile)}>
                    <DynamicBannerSwiper
                      bannerList={bannerListData}
                      variant='blog'
                    />
                    <ShareBlock />

                    <Divider
                      style={{
                        borderColor: 'var(--line_color)',
                        marginTop: '12px',
                        width: '100%',
                      }}
                    />
                  </div>

                  <div className={styles.get_started_wrapper}>
                    <GetStartedBlock />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className={clsx(styles.additional_block, styles.desktop)}>
            <DynamicBannerSwiper bannerList={bannerListData} variant='blog' />
            <ShareBlock />
            <div className={styles.content_menu}>
              <PostContentMenu />
            </div>
          </div>
        </div>
      </section>
    </article>
  );
};

export default PostContent;
