import React from 'react';

import { Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslations } from 'next-intl';
import { toast } from 'react-toastify';

import type { IResponseErrors } from 'services/api.types';
import type { ISetRatingData } from 'services/blog.service';
import { BlogService } from 'services/blog.service';
import { useTheme } from 'hooks/use-theme';

import styles from './rate-article.module.scss';

const StyledRating = styled(Rating)({
  '& .MuiRating-icon': {
    marginRight: '12px',
    transform: 'scale(1)',
  },
  direction: 'ltr',
});

interface IRateArticleProps {
  postId: number;
}

const RateArticle: React.FC<IRateArticleProps> = ({ postId }) => {
  const t = useTranslations('blog');
  const { theme } = useTheme();

  const { data: ratingData, refetch: refetchRatingData } = useQuery({
    enabled: !!postId,
    queryFn: () => BlogService.getRating(String(postId), 5),
    queryKey: ['getRating', postId],
  });

  const { mutate: setRating } = useMutation({
    mutationFn: (data: ISetRatingData) => BlogService.setRating(data),
    onError: (err: IResponseErrors) => {
      toast.error(err?.response?.data?.message);
    },
    onSuccess: async () => await refetchRatingData(),
  });

  const averageRating = ratingData?.rating ?? 0;
  const userRating = ratingData?.user_rating ?? 0;

  const handleChange = (value: number | null) => {
    if (!userRating) {
      setRating({
        postId,
        rating: value || 0,
      });
    }
  };

  return (
    <div className={styles.rate__wrapper}>
      <p className={styles.rate__title}>
        {averageRating && userRating
          ? t('Article rated, thank you!')
          : t('Rate the article')}
      </p>
      <StyledRating
        classes={{ icon: styles.rating_icon, root: styles.rating_root }}
        readOnly={userRating > 0}
        size='large'
        value={userRating}
        emptyIcon={
          <svg
            fill='none'
            height='40'
            viewBox='0 0 40 40'
            width='40'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clipRule='evenodd'
              d='M14.9809 1.35116C15.7281 -0.445421 18.2732 -0.445422 19.0204 1.35116L22.4904 9.69401L31.4972 10.4161C33.4368 10.5716 34.2232 12.9921 32.7455 14.2579L25.8832 20.1361L27.9798 28.9253C28.4312 30.8179 26.3723 32.3139 24.7117 31.2996L17.0006 26.5897L9.28955 31.2996C7.62902 32.3139 5.57003 30.8179 6.0215 28.9253L8.11803 20.1361L1.25578 14.2579C-0.221949 12.9921 0.564506 10.5716 2.50407 10.4161L11.5109 9.69401L14.9809 1.35116Z'
              fill={theme === 'dark' ? '#373737' : '#EBEBEB'}
              fillRule='evenodd'
            />
          </svg>
        }
        icon={
          <svg
            fill='none'
            height='40'
            viewBox='0 0 40 40'
            width='40'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              clipRule='evenodd'
              d='M14.9809 1.35116C15.7281 -0.445421 18.2732 -0.445422 19.0204 1.35116L22.4904 9.69401L31.4972 10.4161C33.4368 10.5716 34.2232 12.9921 32.7455 14.2579L25.8832 20.1361L27.9798 28.9253C28.4312 30.8179 26.3723 32.3139 24.7117 31.2996L17.0006 26.5897L9.28955 31.2996C7.62902 32.3139 5.57003 30.8179 6.0215 28.9253L8.11803 20.1361L1.25578 14.2579C-0.221949 12.9921 0.564506 10.5716 2.50407 10.4161L11.5109 9.69401L14.9809 1.35116Z'
              fill='#FFA64D'
              fillRule='evenodd'
            />
          </svg>
        }
        onChange={(_, value) => handleChange(value)}
      />

      {!!averageRating && !!userRating && (
        <p className={styles.rate__text}>
          {t('The average score is')} {averageRating}/5
        </p>
      )}
    </div>
  );
};

export default RateArticle;
