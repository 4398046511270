'use client';

import React from 'react';

import { MarkdownTable } from './markdown-table/markdown-table';

import type { ComponentPropsWithoutRef } from 'react';
import ReactMarkdown from 'react-markdown';
import type { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import type { NormalComponents } from 'react-markdown/lib/complex-types';
import gfm from 'remark-gfm';

import styles from './markdown-render.module.scss';

interface MarkdownRendererProps {
  markdownText: string;
}

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({
  markdownText,
}) => {
  const isExternalLink = (url?: string) => {
    if (!url) return false;

    const regex = /^(https?:\/\/)?([a-z0-9-]+\.)*cryptomus\.com/;

    return !regex.test(url);
  };

  const renderLink = ({ children, href }: ComponentPropsWithoutRef<'a'>) => {
    const isExternal = isExternalLink(href);

    return (
      <a
        className={styles.link}
        href={href}
        rel={isExternal ? 'nofollow noopener noreferrer' : undefined}
        target={isExternal ? '_blank' : undefined}
      >
        {children}
      </a>
    );
  };

  const customComponents: Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  > = {
    a: renderLink,
    table: ({ children }: { children: React.ReactNode }) => (
      <MarkdownTable>{children}</MarkdownTable>
    ),
  };

  return (
    <ReactMarkdown components={customComponents} remarkPlugins={[gfm]}>
      {markdownText}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
