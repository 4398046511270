import React from 'react';

import clsx from 'clsx';

import styles from './dotted-progress-bar.module.scss';

interface IDottedProgressBarProps {
  activeIndex: number;
  count: number;
}

const DottedProgressBar: React.FC<IDottedProgressBarProps> = ({
  count,
  activeIndex,
}) => (
  <div className={styles.progressBar}>
    {Array.from({ length: count }).map((_, i) => (
      <div
        key={`DottedProgressBarItem-${i + 1}`}
        className={clsx(styles.progressBar__item, {
          [styles.active]: i === activeIndex,
        })}
      />
    ))}
  </div>
);

export default DottedProgressBar;
